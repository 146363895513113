import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import IPImage from '../../../components/IPImage'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { IChannelData, IMenu } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'
import './style.scss'
import IPButton from '../../../components/IPButton'
import IPSelectBox from '../../../components/IPSelectBox/IPSelectBox'
import IPInput from '../../../components/IPInput'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../components/InputCampaign'
import moment from 'moment'
import Table from '../../../components/Table'
import IPCheckbox from '../../../components/IPCheckBox'
import { GetNetChannelListApi } from '../services/getNetChannelListApi'
import { GetModelCompanyListApi } from '../services/getModelCompanyListApi'
import IPFile from '../../../components/IPFile'
import { excelToJSON, exportToXLSX } from '../../../base/utils/dataHelper'
import { SetFaturaApi } from '../services/setFaturaApi'
import ChartsPage1 from './ChartBar'
import StackPage from './Stack'
import { GetNetChannelContentApi } from '../services/getNetChannelContentApi'
import IPModal from '../../../components/IPModal'
import { GetDPPTargetApi } from '../../Dpp/services/getDPPTargetApi'
import { ITarget } from '../../Dpp/models/models'
import { NetLoginCheckApi } from '../services/netLoginCheckApi'

interface INetChannelState {
  //dppData: IDPP,
  product: string
  selectedStatus?: ISelectOption
  selectedModel?: ISelectOption
  selectedCompany?: ISelectOption
  filterModel: ISelectOption[]
  filterCompany: ISelectOption[]
  contentData?: IMenu[]
  myTargets: ITarget[]
  buttonClick: string
  clicked: boolean
  bar?: boolean
  cake?: boolean
  table?: boolean
}

function Index(props: BaseComponentWithContextProps) {
  useEffect(() => {
    netChannelLoginCheck()
  }, [])

  const getInitialState = (): INetChannelState => {
    return {
      // dppData: {
      //   SlideMenu: [],
      // },
      product: '',
      filterModel: [{ label: '', value: 0 }],
      filterCompany: [{ label: '', value: 0 }],
      myTargets: [],
      buttonClick: 'Detayları Göster',
      clicked: false,
      bar: true,
      cake: false,
      table: true,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INetChannelState>(intialState)

  const filterStatus = [
    { label: 'Satılanlar', value: 1 },
    { label: 'Stokta Olanlar', value: 0 },
  ]

  // const getDppBanners = async () => {
  //   props.showLoading()
  //   const api: GetDppBannersApi = new GetDppBannersApi({})
  //   try {
  //     const res: IDPP = await api.getDppBanners();

  //     setState({
  //       dppData: res,
  //     })

  //     props.hideLoading()
  //   } catch (err: any) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: err.description,
  //       showConfirmButton: true,
  //       allowOutsideClick: false,
  //     })
  //     props.hideLoading()
  //   }
  // }

  const [searchParams, setSearchParams] = useSearchParams()
  const [button, setButton] = useState('')
  const [netChannelList, setNetChannel] = useState<IChannelData[]>([])
  const [excelIMEI, setExcel] = useState<any[]>([])

  const changePage = async (param: string) => {
    navigate(`?page=${param}`)
    setButton(param)
    const element = document.getElementById(param) // Replace 'elementId' with the ID of the element you want to scroll to
    // Scroll the page to the element
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const pageControl = async () => {
    const param = searchParams.get('page')
    setButton(param ? param : '')
  }

  const navigate = useNavigate()

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setMonth(6)
  inititalStartDate.setFullYear(2024)

  const toDateTimeLocal = date => {
    if (date != null) {
      return date
        .toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(' ', 'T')
    }
    return undefined
  }
  const [startSatisDate, setStartSatisDate] = useState(null)
  const [endSatisDate, setEndSatisDate] = useState(null)
  const [startAlmaDate, setStartAlmaDate] = useState(inititalStartDate)
  const [endAlmaDate, setEndAlmaDate] = useState(new Date())

  const getDate = async (data: any, title: string) => {
    if (title === 'StartSatis') setStartSatisDate(data)
    else if (title === 'EndSatis') setEndSatisDate(data)
    else if (title === 'StartAlma') setStartAlmaDate(data)
    else if (title === 'EndAlma') setEndAlmaDate(data)
  }

  const getList = async () => {
    // if (startAlmaDate && endAlmaDate) {
    //   const difference = new Date(endAlmaDate).getTime() - new Date(startAlmaDate).getTime()
    //   const absoluteDifference = difference >= 0 ? difference : -difference
    //   const differenceInDays = absoluteDifference / (1000 * 3600 * 24)

    //   if (differenceInDays > 90) {
    //     return Swal.fire({
    //       icon: 'error',
    //       title: 'En fazla 3 aylık rapor alabilirsiniz!',
    //       showConfirmButton: true,
    //       allowOutsideClick: false,
    //     });
    //   }
    // }
    props.showLoading()

    const reqModel: any = {
      StartSatis: startSatisDate ? new Date(startSatisDate) : undefined,
      EndSatis: endSatisDate ? new Date(endSatisDate) : undefined,
      StartAlma: new Date(startAlmaDate),
      EndAlma: new Date(endAlmaDate),
      Company: state.selectedCompany?.value,
      Status: state.selectedStatus?.value === 1 ? true : state.selectedStatus?.value === 0 ? false : undefined,
      Model: state.selectedModel?.value,
      Product: state.product && state.product?.length > 0 ? state.product : undefined,
    }

    const netapi: GetNetChannelListApi = new GetNetChannelListApi({})
    try {
      await netapi
        .getNetChannelList(reqModel)
        .then(result => {
          if (result) {
            setNetChannel(result)
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } catch {
      props.hideLoading()
    }
  }

  const getModelList = async () => {
    props.showLoading()

    const netapi: GetModelCompanyListApi = new GetModelCompanyListApi({})
    try {
      await netapi
        .getDataList()
        .then(result => {
          if (result) {
            const modelOptions = result.Model.map(item => ({ label: item, value: item }))
            const companyOptions = result.Company.map(item => ({ label: item.Name, value: item.Code }))

            setState({ filterModel: modelOptions, filterCompany: companyOptions })
          }

          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } catch {
      props.hideLoading()
    }
  }

  const getNetChannelContent = async () => {
    props.showLoading()

    const contentapi: GetNetChannelContentApi = new GetNetChannelContentApi({})
    try {
      await contentapi
        .getDataList()
        .then(result => {
          if (result) {
            setState({ contentData: result })
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } catch {
      props.hideLoading()
    }
  }

  const netChannelLoginCheck = async () => {
    const check: NetLoginCheckApi = new NetLoginCheckApi({})
    try {
      const response = await check.netLoginCheck()
      if (!response) {
        navigate('/homepage')
      } else {
        pageControl()
        getList()
        getModelList()
        getNetChannelContent()
        getDPPTarget()
      }
    } catch (error) {
      console.error('Error checking net login:', error)
    }
  }

  const faturaKesExcel = async (imeiData: any[]) => {
    var control = false
    if (imeiData.length === 1) {
      await Swal.fire({
        icon: 'question',
        title: `${imeiData[0].imei} seri numarası satıldı işaretlenecektir. Devam etmek istiyor musunuz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        if (res.isConfirmed) control = true
      })
    } else {
      await Swal.fire({
        icon: 'question',
        title: `${imeiData
          .map(item => item.imei)
          .join(', ')}, seri numaraları satıldı işaretlenecektir. Devam etmek istiyor musunuz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        if (res.isConfirmed) control = true
      })
    }

    if (control) {
      const faturaapi: SetFaturaApi = new SetFaturaApi({})
      try {
        await faturaapi
          .setFatura(imeiData)
          .then(result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'İşlem başarılı.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              getList()
            }

            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      } catch {
        props.hideLoading()
      }
    }
  }

  const faturaKes = async (imeiData: string, tarih: string, check: boolean) => {
    var control = false
    if (tarih.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'Tarih Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
    if (imeiData) {
      await Swal.fire({
        icon: 'question',
        title: `${imeiData} seri numarası ${
          !check ? 'satıldı işaretlenecektir.' : 'stoğa geri eklenecektir.'
        } Devam etmek istiyor musunuz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        if (res.isConfirmed) control = true
      })
    }

    if (control) {
      const req: any[] = [
        {
          imei: imeiData,
          date: tarih,
        },
      ]

      const faturaapi: SetFaturaApi = new SetFaturaApi({})
      try {
        await faturaapi
          .setFatura(req)
          .then(result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'İşlem başarılı.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              getList()
            }

            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      } catch {
        props.hideLoading()
      }
    }
  }

  const columnDefinitions = [
    { Header: '#', accessor: 'order' },
    { Header: 'Satıldı', accessor: 'selectAll' },
    { Header: 'Satın Alma Tarihi', accessor: 'alTarih' },
    { Header: 'Ürün Kodu', accessor: 'productCode' },
    { Header: 'Ürün Açıklaması', accessor: 'productInfo' },
    { Header: 'Ürün Modeli', accessor: 'productModel' },
    { Header: 'IMEI', accessor: 'imei' },
    { Header: 'Şirket', accessor: 'company' },
    { Header: 'Satış Tarihi', accessor: 'satTarih' },
    { Header: 'Durum', accessor: 'status' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableDataAndTotal = useMemo(() => {
    const tableData = netChannelList.map((item, index) => {
      const updateSatTarih = newDate => {
        const newData = [...netChannelList]
        newData[index].SatisTarihi = newDate
      }
      const data = {
        selectAll: (
          <IPCheckbox
            label=''
            onCheckedChange={(checked: boolean) => {
              if (item.SatisTarihi === null) faturaKes(item.IMEINumber, '', item.Status)
              else faturaKes(item.IMEINumber, item.SatisTarihi.toString(), item.Status)
            }}
            checked={item.Status}
          />
        ),
        alTarih: item.AlmaTarihi && moment(item.AlmaTarihi).format('DD.MM.YYYY'),
        productCode: item.ProductCode,
        productInfo: item.ProductInfo,
        productModel: item.ProductModel,
        imei: item.IMEINumber,
        order: index + 1,
        company: item.Company,
        satTarih: (
          <InputCampaign
            type='date'
            id={`date_${index}`} // Her giriş alanının benzersiz bir ID'si olsun
            label={''}
            value={toDateTimeLocal(item.SatisTarihi?.toString().split('T')[0])}
            onChange={e => {
              updateSatTarih(e.target.value)
            }}
            isActive
          />
        ),
        status:
          item.Status === false ? (
            <span style={{ color: 'red' }}>Stokta</span>
          ) : (
            <span style={{ color: 'green' }}>Satıldı</span>
          ),
      }
      return data
    })
    return {
      tableData,
    }
  }, [netChannelList])

  const postExcel = async () => {
    try {
      if (netChannelList) {
        const data: any[] = []
        if (netChannelList.length > 0) {
          netChannelList.map(item => {
            return data.push({
              'Satın Alma Tarihi': item.AlmaTarihi && moment(item.AlmaTarihi).format('DD.MM.YYYY'),
              'Ürün Kodu': item.ProductCode,
              'Ürün Açıklaması': item.ProductInfo,
              'Ürün Modeli': item.ProductModel,
              IMEI: item.IMEINumber,
              'Satış Tarihi': item.SatisTarihi && moment(item.SatisTarihi).format('DD.MM.YYYY'),
              Durum: item.Status === false ? 'Stokta' : 'Satıldı',
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Apple NetChannel')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const handleFiles = async (files: File[]) => {
    const imeiNumbers: any = await excelToJSON(files[0])

    const codeData = imeiNumbers[0].map(item => ({
      imei: String(item.IMEI),
      date: String(item.Tarih),
    }))

    let list: any[] = []

    codeData.forEach((codeItem, index) => {
      if (netChannelList.some((item: any) => !item.Status && item.IMEINumber === String(codeItem.imei))) {
        list.push(codeItem)
      }
    })

    setExcel(list)
    if (list.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'IMEI Bulunamadı!',
        allowOutsideClick: true,
        backdrop: true,
      })
    }
    faturaKesExcel(list)
  }

  const modelAdet = (model: any, type: string) => {
    if (state.filterModel != null && netChannelList != null) {
      const productModelCounts = {}
      netChannelList.forEach(item => {
        const productModel = item.ProductModel
        if (productModel in productModelCounts) {
          productModelCounts[productModel]++
        } else {
          productModelCounts[productModel] = 1
        }
      })

      if (type === 'Toplam') {
        // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
        const optionData = state.filterModel.map(option => {
          const productModel = option.value
          return parseInt(productModelCounts[productModel]) || 0 // Eğer ProductModel yoksa, 0 olarak dön
        })

        // optionData içindeki değerleri int yap ve topla
        const total = optionData.reduce((acc, val) => acc + val, 0)

        return total
      } else {
        // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
        const optionData = state.filterModel
          .filter(a => a.value === model)
          .map(option => {
            const productModel = option.value
            return productModelCounts[productModel] || 0 // Eğer ProductModel yoksa, 0 olarak dön
          })
        return optionData
      }
    }
  }

  const modelPercentage = (model: any) => {
    const total = modelAdet(state.filterModel, 'Toplam')
    const adet = modelAdet(model, 'Adet')

    if (total === 0) return 0

    if (adet !== undefined && total !== undefined) return ((adet[0] / Number(total)) * 100).toFixed(2)
  }

  const [days, setDays] = useState<number>(0)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)

  const getDPPTarget = async () => {
    const getLicenseTracking: GetDPPTargetApi = new GetDPPTargetApi({})
    getLicenseTracking
      .getDPPTargets()
      .then(result => {
        if (result) {
          setState({ myTargets: result })
        } else {
          console.warn('DPPTargets result is empty.')
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          console.log('Hedef verisi bulunamadı')
        } else {
          console.log(err.description)
        }
      })
    return
  }

  const bitisTarihleri = state.myTargets.map(x => x.BITIS_TARIHI)

  useEffect(() => {
    if (bitisTarihleri?.length > 0) {
      const target1 = bitisTarihleri[0]
      if (target1) {
        const [year, month, day] = [
          parseInt(target1.substring(0, 4)),
          parseInt(target1.substring(4, 6)) - 1,
          parseInt(target1.substring(6, 8)),
        ]
        const bitisTarihi = new Date(year, month, day, 23, 59, 59)

        const timerInterval = setInterval(() => {
          const now = new Date()
          const difference = Math.max(bitisTarihi.getTime() - now.getTime(), 0) / 1000

          setDays(Math.floor(difference / 86400))
          setHours(Math.floor((difference % 86400) / 3600))
          setMinutes(Math.floor((difference % 3600) / 60))
          setSeconds(Math.floor(difference % 60))

          if (difference <= 0) {
            clearInterval(timerInterval)
          }
        }, 1000)

        return () => {
          clearInterval(timerInterval)
        }
      }
    } else {
    }
  }, [bitisTarihleri])

  const targetDetails = () => {
    if (state.clicked) setState({ buttonClick: 'Detayları Göster', clicked: false })
    else {
      setState({ buttonClick: 'Detayları Gizle', clicked: true })
    }
  }

  const uniqueTargets: string[] = []

  // state.myTargets dizisini kontrol edin ve eşsiz HEDEF_ADI'ları bulun
  state.myTargets &&
    state.myTargets.forEach(left => {
      if (!uniqueTargets.includes(left.HEDEF_ADI)) {
        uniqueTargets.push(left.HEDEF_ADI)
      }
    })

  const createTable = () => {
    return (
      <table className='secondBox'>
        <thead className='sbHead'>
          <tr>
            <th></th>
            <th>Hedef</th>
            <th>Gerçekleşen</th>
            <th>Kalan</th>
          </tr>
        </thead>
        <tbody>
          {state.myTargets.map((a, index) => (
            <tr key={index}>
              <td>{a.KOTA === '252' ? 'Mac' : a.KOTA === '253' ? 'iPad' : a.KOTA === '254' ? 'İphone' : ''}</td>
              <td>{a.HEDEF}</td>
              <td>{a.SATIS}</td>
              <td>{Number(a.HEDEF) - Number(a.SATIS)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const targetDivs = uniqueTargets.map((hedefAdi, index) => (
    <div className='mainBox' key={index}>
      <div className='row firstBox'>
        <div className='targetName col-lg-3'>
          <span>{hedefAdi}</span>
        </div>
        <div className='timer col-lg-6'>
          <div className='timer-box'>
            <span className='timer-value'>{days}</span>
            <span className='timer-label'>GÜN</span>
          </div>
          <div className='timer-box'>
            <span className='timer-value'>{hours}</span>
            <span className='timer-label'>SAAT</span>
          </div>
          <div className='timer-box'>
            <span className='timer-value'>{minutes}</span>
            <span className='timer-label'>DAKİKA</span>
          </div>
          <div className='timer-box'>
            <span className='timer-value'>{seconds}</span>
            <span className='timer-label'>SANİYE</span>
          </div>
        </div>
        <div className='col-lg-3'>
          <IPButton type='button' className='detailsBtn' text={state.buttonClick} onClick={targetDetails} />
        </div>
      </div>
      <br></br>
      {state.clicked && createTable()}
    </div>
  ))

  const [showSeed, setShowSeed] = useState(false)

  return (
    <>
      <div className='container current-account'>
        <div className='content col-lg-12 m-auto'>
          <div className=''>
            <div className='card-body'>
              <br></br>
              {/*ÜRÜNLER*/}
              {state.contentData && (
                <div className='m-t-10 mb-5'>
                  <div className='row' style={{ placeContent: 'center' }}>
                    {state.contentData
                      .filter(a => a.Location === 2)
                      .map((iphone, index) => (
                        <div className='col-lg-1' style={{ textAlignLast: 'center', whiteSpace: 'nowrap' }} key={index}>
                          {iphone.Link ? (
                            <Link to={iphone.Link}>
                              <IPImage src={iphone.Image} alt='' className='popular_product' />
                            </Link>
                          ) : (
                            iphone.Image && <IPImage src={iphone.Image} alt='' className='popular_product' />
                          )}
                          {iphone.Name && <h6>{iphone.Name}</h6>}
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {/* BANNER */}
              {state.contentData && (
                <>
                  <div className='card'>
                    <Swiper
                      cssMode={true}
                      navigation={true}
                      keyboard={true}
                      pagination={{ clickable: true }}
                      observer={true}
                      observeParents={true}
                      modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                      className='mySwiper'
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                    >
                      {state.contentData.map(
                        (slide, index) =>
                          slide.Location === 1 &&
                          (slide.Link ? (
                            <SwiperSlide key={index}>
                              <a href={slide.Link} target='_blank' rel='noreferrer'>
                                <IPImage
                                  className='banner'
                                  style={{ height: '100%', padding: '5px 0 0 0' }}
                                  src={slide.Image}
                                  alt=''
                                />
                              </a>
                            </SwiperSlide>
                          ) : (
                            <SwiperSlide key={index}>
                              <IPImage
                                className='banner'
                                style={{ height: '100%', padding: '5px 0 0 0' }}
                                src={slide.Image}
                                alt=''
                              />
                            </SwiperSlide>
                          ))
                      )}
                    </Swiper>
                  </div>
                  <br></br>
                </>
              )}
              {/* HEDEF TAKİP */}
              {targetDivs}
              <div className='row m-t-10 mb-5'>
                <div className='col d-flex card-mobile-resize justify-content-center'>
                  <div className='room' onClick={() => changePage('report')}>
                    <div className='room image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='NetChannel NetChannel-original'
                          src='/images/netChannel/detay2.png'
                          alt=''
                        />
                        <IPImage className='NetChannel NetChannel-hover' src='/images/netChannel/detay1.png' alt='' />
                      </div>
                    </div>
                  </div>
                  <div className='room' onClick={() => changePage('dashboard')}>
                    <div className='room ms-5 text-center  image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='NetChannel NetChannel-original'
                          src='/images/netChannel/Dashboard.png'
                          alt=''
                        />
                        <IPImage
                          className='NetChannel NetChannel-hover'
                          src='/images/netChannel/dashboard_beyaz.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <Link to={'../netChannelEducation'} target='_blank'>
                    <div className='room'>
                      <div className='room ms-5 text-center  image-hover'>
                        <div className='room-image text-center'>
                          <IPImage
                            className='NetChannel NetChannel-original'
                            src='/images/netChannel/eduAcik.png'
                            alt=''
                          />
                          <IPImage
                            className='NetChannel NetChannel-hover'
                            src='/images/netChannel/eduKoyu.png'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IPModal show={showSeed} hideConfirm={true} width={500} onClose={() => setShowSeed(false)}>
          <div className='etkin d-flex justify-content-center'>
            <div className='seed'>
              <a href='/assetWeb/docs/SEED Kayıt - DPP.pdf' target='_blank' className='file'>
                <IPButton
                  type='button'
                  className='btn btn-primary m-l-10'
                  text='SEED Kayıt'
                  style={{ margin: '15px' }}
                />
              </a>
            </div>
            <div className='seedlogin'>
              <a
                href='https://idmsa.apple.com/IDMSWebAuth/signin?accountName=null&appIdKey=f5824a1b767cb641b2e714df18b5347afa62797b7a1c18f449999755578e2b82&rv=2&offerNativeTakeOver=false&path=/&language=US-EN'
                target='_blank'
                className='file'
                rel='noreferrer'
              >
                <IPButton type='button' className='btn btn-success' text='SEED Giriş' style={{ margin: '15px' }} />
              </a>
            </div>
          </div>
        </IPModal>
      </div>
      <br></br>
      <br></br>
      {button !== 'education' && (
        <form className='formbanner'>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-2'>
              <InputCampaign
                type='date'
                id='StartDateAlma'
                name='StartDateAlma'
                label={'Satın Alma Başlangıç Tarihi'}
                value={toDateTimeLocal(startAlmaDate)}
                onChange={e => getDate(e.target.value, 'StartAlma')}
                isActive
              />
            </div>
            <div className='col-lg-2'>
              <div className='product'>
                <InputCampaign
                  type='date'
                  id='EndDateAlma'
                  name='EndDateAlma'
                  label={'Satın Alma Bitiş Tarihi'}
                  value={toDateTimeLocal(endAlmaDate)}
                  onChange={e => getDate(e.target.value, 'EndAlma')}
                  isActive
                />
              </div>
            </div>
            <div className='col-lg-2'>
              <IPSelectBox
                isClearable
                id='Şirket'
                name='Şirket'
                options={state.filterCompany}
                placeholder={'Şirket Seçiniz'}
                value={state.selectedCompany}
                onChangeSingle={option => {
                  setState({ selectedCompany: option })
                }}
              />
            </div>
            <div className='col-lg-2'>
              <IPSelectBox
                isClearable
                id='Model'
                name='Model'
                options={state.filterModel}
                placeholder={'Model Seçiniz'}
                value={state.selectedModel}
                onChangeSingle={option => {
                  setState({ selectedModel: option })
                }}
              />
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-2'>
              <InputCampaign
                type='date'
                id='StartDateSatis'
                name='StartDateSatis'
                label={'Satış Başlangıç Tarihi'}
                value={toDateTimeLocal(startSatisDate)}
                onChange={e => getDate(e.target.value, 'StartSatis')}
                isActive
              />
            </div>
            <div className='col-lg-2'>
              <div className='product'>
                <InputCampaign
                  type='date'
                  id='EndDateSatis'
                  name='EndDateSatis'
                  label={'Satış Bitiş Tarihi'}
                  value={toDateTimeLocal(endSatisDate)}
                  onChange={e => getDate(e.target.value, 'EndSatis')}
                  isActive
                />
              </div>
            </div>
            <div className='col-lg-2'>
              <IPInput
                type='text'
                className='form-control'
                id='product'
                placeholder={'Ürün Kodu/Açıklaması/IMEI'}
                value={state.product}
                onChange={e => {
                  setState({ product: e.target.value })
                }}
              />
            </div>
            <div className='col-lg-2'>
              <IPSelectBox
                isClearable
                id='Durum'
                name='Durum'
                placeholder={'Durum Seçiniz'}
                options={filterStatus}
                value={state.selectedStatus}
                onChangeSingle={option => {
                  setState({ selectedStatus: option })
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'></div>
            <div className='col-lg-2'>
              <IPButton text='Listele' className='button' onClick={() => getList()} />
            </div>
            <div className='col-lg-5'></div>
          </div>
        </form>
      )}

      {button === 'report' && (
        <>
          <hr />
          <br></br>
          <div id='report'>
            <h2 style={{ textAlign: 'center' }}>Net Channel Satış Detayları</h2>
            <div className='row ml-4 mr-4'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-2'>
                    <IPFile
                      id='FileInputDealerCode'
                      multiple={false}
                      handleFiles={e => handleFiles(e)}
                      className='campaignFileInput'
                      label="Excel'den Yükle"
                      removeAfterSelect={true}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <a className='campaignExcelBtn' href='/assetWeb/docs/netChannelIMEI.xlsx' target='_blank'>
                      Örnek Excel
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'end' }}>
                <a className='excel-button ms-2 d-flex justify-content-center align-items-center' onClick={postExcel}>
                  <img
                    src='/images/icons/excel.png'
                    alt=''
                    width={25}
                    height={25}
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                  />
                  Excel'e Gönder
                </a>
              </div>
            </div>

            <div className='mr-4 ml-4'>
              <Table columns={tableColumns} data={tableDataAndTotal.tableData} currentPage={0} columnCount={9} />
            </div>
          </div>
          <div className='m-5'>
            <b>Toplam {tableDataAndTotal.tableData.length} adet veri bulundu</b>
          </div>
        </>
      )}
      {button === 'dashboard' && (
        <>
          {netChannelList && state.filterModel && (
            <>
              <hr />
              <br></br>
              <div className='row d-flext justify-content-center m-5' id='dashboard'>
                <div className='col-lg-2'>
                  <IPCheckbox label='Sütun Grafiği' onClick={() => setState({ bar: !state.bar })} checked={state.bar} />
                </div>
                <div className='col-lg-2'>
                  <IPCheckbox
                    label='Pasta Grafiği'
                    onClick={() => setState({ cake: !state.cake })}
                    checked={state.cake}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPCheckbox label='Tablo' onClick={() => setState({ table: !state.table })} checked={state.table} />
                </div>
              </div>
              <br></br>
              <div className='row d-flext justify-content-center m-5'>
                {state.bar && (
                  <div className='col-lg-4'>
                    <StackPage
                      report={netChannelList}
                      options={state.filterModel.filter(a => {
                        var data = modelAdet(a.value, 'Adet')
                        if (Array.isArray(data) && data.some(item => item === 0)) {
                          return false // 0 olanları filtrele
                        }
                        return true
                      })}
                    />
                  </div>
                )}
                {state.cake && (
                  <div className='col-4'>
                    <ChartsPage1
                      report={netChannelList}
                      options={state.filterModel.filter(a => {
                        var data = modelAdet(a.value, 'Adet')
                        if (Array.isArray(data) && data.some(item => item === 0)) {
                          return false // 0 olanları filtrele
                        }
                        return true
                      })}
                    />
                  </div>
                )}
                {state.table && (
                  <div className='col-4'>
                    <table className='table table-bordered dataTable XmlTable'>
                      <tbody>
                        <tr role='row'>
                          <td
                            style={{
                              width: '100px',
                              backgroundColor: '#F1F2F2',
                              height: 300,
                            }}
                            rowSpan={
                              state.filterModel.filter(a => {
                                var data = modelAdet(a.value, 'Adet')
                                if (Array.isArray(data) && data.some(item => item === 0)) {
                                  return false // 0 olanları filtrele
                                }
                                return true // 0 olmayanları tut
                              }).length + 1
                            }
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height:
                                  (state.filterModel.filter(a => {
                                    var data = modelAdet(a.value, 'Adet')
                                    if (Array.isArray(data) && data.some(item => item === 0)) {
                                      return false // 0 olanları filtrele
                                    }
                                    return true // 0 olmayanları tut
                                  }).length -
                                    1) *
                                    75 +
                                  'px', // div bileşeninin yüksekliği

                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                              <p
                                style={{
                                  fontSize: '1.3rem',
                                  color: '#00A843',
                                  fontWeight: '600',
                                }}
                              >
                                {modelAdet(state.filterModel, 'Toplam')}
                              </p>
                            </div>
                          </td>
                        </tr>
                        {state.filterModel.map(data => {
                          const adet = modelAdet(data.value, 'Adet')
                          const percentage = modelPercentage(data.value)
                          if (Array.isArray(adet) && adet.some(item => item === 0)) {
                            return null // Dizideki herhangi bir eleman 0 ise map'i atla
                          }
                          return (
                            <tr className='' key={data.value}>
                              <td style={{ verticalAlign: 'middle' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span style={{ color: '#676767', fontWeight: '500' }}>{data.value}</span>
                                  <p
                                    style={{
                                      fontSize: '1.3rem',
                                      color: '#00A843',
                                      fontWeight: '600',
                                      marginBottom: 0,
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    {adet} ({percentage}%)
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      <br></br>
      <br></br>
    </>
  )
}
export default componentWithContext(Index)
